import axios from "axios";

const settings = {
    isUpdate: false,
    baseUrl: 'https://apiv2.eyeinc.ru/',
}


const client = axios.create({
    baseURL: settings.baseUrl
})

client.interceptors.request.use((config) => {
    if (config.headers.common.Authorization == null) {
        config.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
    }

    if (config.headers['Content-Type'] == null) {
        config.headers.common['Content-Type'] = 'application/json'
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

client.interceptors.response.use((resp) => {
    return resp
}, (error) => {
    if (error.response == null) throw error
    if (error.response.status === 401) {
        const refreshToken = localStorage.getItem('refreshToken')
        console.log(settings.isUpdate)
        if (!settings.isUpdate) {
            settings.isUpdate = true

            return client.put(`auth/${refreshToken}`, null).then((response) => {
                if (response == null) {
                    // document.location.href = 'auth'
                }

                if (response.status === 400) {
                    document.location.href = '/auth'
                }

                const token = response.data

                localStorage.setItem('accessToken', token.accessToken)
                localStorage.setItem('refreshToken', token.refreshToken)

                error.config.headers.Authorization = `Bearer ${token.accessToken}`
                settings.isUpdate = false
                return axios.request(error.config)
            })
        }
    } else {
        return error.response
    }
})

export default client
