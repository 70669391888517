<template>
  <div class="settings">
    <div
      class="settings__item"
      :class="[getTheme, getType === 'default' ? 'active' : '']"
      @click="changeView('default')"
    >
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.425 0C0.637994 0 0 0.637994 0 1.425V1.575C0 2.36201 0.637994 3 1.425 3C2.21201 3 2.85 2.36201 2.85 1.575V1.425C2.85 0.637994 2.21201 0 1.425 0ZM4.765 0C3.96971 0 3.325 0.64471 3.325 1.44V1.56C3.325 2.35529 3.96971 3 4.765 3H17.56C18.3553 3 19 2.35529 19 1.56V1.44C19 0.64471 18.3553 0 17.56 0H4.765ZM4.765 5C3.96971 5 3.325 5.64471 3.325 6.44V6.56C3.325 7.35529 3.96971 8 4.765 8H17.56C18.3553 8 19 7.35529 19 6.56V6.44C19 5.64471 18.3553 5 17.56 5H4.765ZM3.325 11.44C3.325 10.6447 3.96971 10 4.765 10H17.56C18.3553 10 19 10.6447 19 11.44V11.56C19 12.3553 18.3553 13 17.56 13H4.765C3.96971 13 3.325 12.3553 3.325 11.56V11.44ZM0 6.425C0 5.63799 0.637994 5 1.425 5C2.21201 5 2.85 5.63799 2.85 6.425V6.575C2.85 7.36201 2.21201 8 1.425 8C0.637994 8 0 7.36201 0 6.575V6.425ZM1.425 10C0.637994 10 0 10.638 0 11.425V11.575C0 12.362 0.637994 13 1.425 13C2.21201 13 2.85 12.362 2.85 11.575V11.425C2.85 10.638 2.21201 10 1.425 10ZM3.325 16.44C3.325 15.6447 3.96971 15 4.765 15H17.56C18.3553 15 19 15.6447 19 16.44V16.56C19 17.3553 18.3553 18 17.56 18H4.765C3.96971 18 3.325 17.3553 3.325 16.56V16.44ZM1.425 15C0.637994 15 0 15.638 0 16.425V16.575C0 17.362 0.637994 18 1.425 18C2.21201 18 2.85 17.362 2.85 16.575V16.425C2.85 15.638 2.21201 15 1.425 15Z"
          fill="#C5C4C6"
        />
      </svg>
    </div>
    <div
      class="settings__item"
      v-if="$windowWidth < 993"
      :class="[
        getTheme,
        getType === 'list' ? 'active' : '',
      ]"
      @click="changeView('list')"
    >
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 1.92C0 0.859614 0.859613 0 1.92 0H17.08C18.1404 0 19 0.859613 19 1.92V3.78C19 4.84039 18.1404 5.7 17.08 5.7H1.92C0.859614 5.7 0 4.84039 0 3.78V1.92ZM0 8.57C0 7.50961 0.859613 6.65 1.92 6.65H17.08C18.1404 6.65 19 7.50961 19 8.57V10.43C19 11.4904 18.1404 12.35 17.08 12.35H1.92C0.859614 12.35 0 11.4904 0 10.43V8.57ZM1.92 13.3C0.859613 13.3 0 14.1596 0 15.22V17.08C0 18.1404 0.859614 19 1.92 19H17.08C18.1404 19 19 18.1404 19 17.08V15.22C19 14.1596 18.1404 13.3 17.08 13.3H1.92Z"
          fill="#C5C4C6"
        />
      </svg>
    </div>
    <div
      class="settings__item"
      :class="[
        getTheme,
        getType === 'column' ? 'active' : '',
      ]"
      @click="changeView('column')"
    >
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.92 0C0.859613 0 0 0.859614 0 1.92V7.105C0 8.16539 0.859614 9.025 1.92 9.025H7.105C8.16539 9.025 9.025 8.16539 9.025 7.105V1.92C9.025 0.859613 8.16539 0 7.105 0H1.92ZM11.895 0C10.8346 0 9.975 0.859614 9.975 1.92V7.105C9.975 8.16539 10.8346 9.025 11.895 9.025H17.08C18.1404 9.025 19 8.16539 19 7.105V1.92C19 0.859613 18.1404 0 17.08 0H11.895ZM0 11.895C0 10.8346 0.859613 9.975 1.92 9.975H7.105C8.16539 9.975 9.025 10.8346 9.025 11.895V17.08C9.025 18.1404 8.16539 19 7.105 19H1.92C0.859614 19 0 18.1404 0 17.08V11.895ZM11.895 9.975C10.8346 9.975 9.975 10.8346 9.975 11.895V17.08C9.975 18.1404 10.8346 19 11.895 19H17.08C18.1404 19 19 18.1404 19 17.08V11.895C19 10.8346 18.1404 9.975 17.08 9.975H11.895Z"
          fill="#C5C4C6"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "TypeSettings",
  computed: {
    getType() {
      return this.$store.getters.getTypeView;
    },
    getTheme() {
      return this.getType === "default"
        ? "settings__item_white"
        : "settings__item_black";
    },
  },
  methods: {
    changeView(type) {
      if (this.$store.getters.getIsPay) {
          this.$store.commit("changeViewType", type);
      } else {
        this.$store.commit("changeViewType", "default");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__item {
    cursor: pointer;
    margin-right: 16px;
    &_black {
      svg {
        * {
          fill: #fff;
        }
      }
    }
    &.active {
      svg {
        * {
          fill: #fd5739;
        }
      }
    }
    svg {
      * {
        transition: all 0.5s;
      }
    }
    &_disabled {
      svg {
        opacity: 0.2;
        * {
          fill: #c5c4c6;
        }
      }
    }
  }
}
</style>