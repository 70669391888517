<template>
    <modal name="ShareLinkModal" :width="width" :height="height" @before-open="onBeforeOpen">
        <div class="modal">
            <div class="modal__head">
                <a @click="onClose()"><span class="icon-close"></span></a>
            </div>
            <div class="modal__body">
                <p class="modal__title">Предоставьте доступ к камере по ссылке</p>
                <div class="uri--container">
                    <p class="url__text">{{url}}</p>
                    <div class="success--container" :class="{'showed' : isShow}">
                        <p>ССЫЛКА СКОПИРОВАНА</p>
                    </div>
                </div>
                <div class="share-buttons--container">
                    <button @click="copy">КОПИРОВАТЬ ССЫЛКУ</button>
                    <p>ДОСТУП К ВИДЕО ОТКРЫТ НА 60 МИНУТ</p>
                </div>
                <div class="modal__button">
                    <button @click="onClose">ОК</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>

    export default {
        name: 'VideoShareModal',
        data () {
            return {
                isShow: false,
                url: ''
            }
        },
        computed: {
            width () {
                return (this.isMobile) ? '308px' : '500px'
            },

            height () {
                return (this.isMobile) ? '340px' : '240px'
            },

            isMobile () {
                return (window.screen.width < 993)
            }
        },
        methods: {
            onClose () {
                this.$modal.hide('ShareLinkModal')
            },

            onDecline () {
                this.onClose()
            },

            onConfirm () {
                this.onClose()
                this.$emit('confirm')
            },

            copy () {
                const el = document.createElement('textarea')
                el.value = this.url
                document.body.appendChild(el)
                el.select()
                document.execCommand('copy')
                document.body.removeChild(el)

                this.isShow = true
                setTimeout(() => {
                    this.isShow = false
                }, 1500)
            },

            onBeforeOpen (e) {
                this.url = e.params.url
            }
        }

    }

</script>

<style scoped lang="scss">


    .modal__title{
      line-height: 18px;
    }


    .uri--container{
        margin-top: 25px;
        border-radius: 8px 8px 0 0;
        width: 460px;
        height: 60px;
        background-color: #F8F9FA;
        border-bottom: 4px solid #E74900;
        position: relative;
        display: flex;
        @include media(992) {
            width: 270px;
        }

        .url__text{
            font-family: Roboto, "sans-serif";
            font-weight: 500;
            font-size: 12px;
            color: #4A4A4A;
            letter-spacing: 0;
            line-height: 16px;
            margin-top: 11px;
            margin-left: 9px;
            width: 254px;
            height: 39px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          width: auto;

        }

        .success--container{
            opacity: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            transition: opacity .5s;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(248,249,250,.8);
            border-radius: 8px 8px 0 0 ;

            p{
                font-family: Roboto, "sans-serif";
                font-size: 12px;
                color: #4A4A4A;
                letter-spacing: 0;
                text-align: center;
                line-height: 16px;
                font-weight: 500;
            }
            &.showed{
                opacity: 1;
            }
        }

    }

    .share-buttons--container{
        margin-top: 17px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        @include media(992) {
            flex-direction: column;
          align-items: center;
        }


        button{
            font-family: Roboto, "sans-serif";
            font-weight: 700;
            font-size: 12px;
            color: #E74900;
            letter-spacing: 0;
            text-align: center;
            line-height: 17px;
            border: 0;
            outline: 0;
            height: 17px;
            background-color: transparent;
            cursor: pointer;
        }

        p{
            font-family: Roboto, "sans-serif";
            font-size: 14px;
            color: #6E6E6E;
            letter-spacing: 0;
            text-align: center;
            line-height: 18px;
            width: 287px;

          @include media(992){
            width: 183px;
            margin-top: 37px;

          }
        }
    }

    .modal__button{
      margin-top: 20px;
      display: flex;
      flex-direction: row-reverse;
      margin-right: -20px;

      @include media(992){
        margin-top: 30px;
      }
      button{
        font-family: Roboto, "sans-serif";
        font-weight: 400;
        font-size: 12px;
        color: #CA4EBB;
        letter-spacing: 2px;
        text-align: center;
        cursor: pointer;
        border: 0;
        outline: 0;
        margin-right: 4px;
        padding-left: 21px;
        padding-right: 21px;
        height: 32px;
        border-radius: 8px;
        background-color: transparent;
        width: 62px;
        &:hover{
          background-color: #F1F1F1;

        }
      }
    }

</style>
