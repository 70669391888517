<template>
    <div class="search">
        <img src="../../assets/icons/search.svg" >
        <input :class="'input_' + theme" placeholder="Найти камеру" :value="$store.getters.getSearch" @input="$store.commit('changeSearch', $event.target.value)">
    </div>
</template>

<script>
    export default {
        name: "SearchInput",
        props: {
            theme: {
                default: 'dark'
            }
        }
    }
</script>

<style scoped lang="scss">
    .search {
        max-width: 340px;
        width: 100%;
        margin: 0 auto;
        height: 36px;
        background-color: rgba(174,174,174,0.20);
        border: 1px solid rgba(151,151,151,0.05);
        display: flex;
        align-items: center;
        border-radius: 8px;
        img{
            margin-left: 18px;
        }
        input{
            margin-left: 15px;
            width: 273px;
            height: 36px;
            font-family: Roboto, "sans-serif";
            font-weight: 400;
            font-size: 15px;
            margin-top: 0px;
            line-height: 19px;
            letter-spacing: 0;
            border: 0;
            outline: 0;
            background-color: transparent;
            
            &.input_white {
                color: #fff;
            }
            &::placeholder{
                font-weight: 100;
                color: #4a4a4a;

            }
        }

    }
</style>