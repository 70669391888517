import axios from "../libs/axios";
import _ from 'lodash'
export default {
    state: {
        account: {},
        subaccount: {},
        childs: {},
        shareData: {},
        cameras: []
    },
    getters: {
        getAccount(state) {
            return state.account
        },
        getCameras(state) {
            return state.cameras
        },
        getIsPay(state) {
            if (state.account && (state.account.isFree || state.account.isPaid)) {
                return true
            } else {
                return false
            }
        },
        getSubAccount(state) {
            return state.account
        }
    },
    mutations: {
        changeAccount(state, payload) {
            if (!payload.childUsers.length) {
                state.cameras = payload.cameras
            }
            else {
                const account = localStorage.getItem('subAccount')
                if (account) {
                    const cameras = _.find(payload.childUsers, item => {
                        return item.id == account
                    })
                    if (cameras) {
                        state.cameras = cameras.cameras
                    }
                }
            }
            return state.account = payload
        },
        changeCameras(state, payload) {
            return state.cameras = payload
        },
        clearAccount(state) {
            state.cameras = []
            return state.account = {}
        },
        changePayStatus(state) {
            const invoices = state.account.invoices
            if (invoices && invoices.length) {
                for (const i in invoices) {
                    if (!invoices[i].status) {
                        invoices[i].status = true
                    }
                }
                state.account.invoices = invoices
            }
        },
        async changeAutoPay(state, payload) {
            state.account.payCard.isEnableRecurrent = payload
            let resp
            if (payload) {
                resp = await axios.post('payment/recurrent')
            } else {
                resp = await axios.delete('payment/recurrent')
            }
            return resp.data
        },
        SET_CARD_DELETE: (state) => {
            return state.account.payCard = false
        },
        changePaySuccess(state, payload) {
            const findPay = _.findIndex(state.account.invoices, function (item) {
                return item.id === payload
            })
            if (findPay !== -1) {
                state.account.invoices[findPay].status = true
                state.account.invoices[findPay].date = new Date().getDate()
            }
        }
    },
    actions: {
        PaymentsDeleteCard: async ({ commit }, payload) => {
            const resp = await axios.delete(`card/${payload}`)
            if (resp.status) {
                commit('SET_CARD_DELETE')
            }
        },
        async loadAccount({
            commit
        }) {
            const account = await axios.get('/user')
            if (account.status === 200) {
                commit('changeAccount', account.data)
            }
        },
        async auth(state, {
            login,
            password
        }) {
            login = login.substr(0, 16).replace(/[^a-zA-Z0-9]/g, '')
            return await axios.post('/auth', {
                phone: login,
                password: password
            })
        },
        async sendPayments() {
            const res = await axios.post('payment/recurring')
            return res.data
        },
        exitFromAccount({
            commit
        }) {
            localStorage.removeItem('typeView')
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            commit('clearAccount')
        }
    },
}