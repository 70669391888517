<template>
  <div class="avatar">
    <img
      :src="getAccount.avatar"
      alt=""
      class="avatar__image"
      @click="$emit('changeMenu')"
    />
    <div class="avatar__dot" v-if="!getPay && isPay">
      <svg
        width="4"
        height="11"
        viewBox="0 0 4 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.904692"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.90299 3.19022C2.90299 3.97399 2.87522 4.69164 2.81966 5.3432C2.76411 5.99476 2.69466 6.65103 2.61133 7.31204H1.41688C1.32429 6.65103 1.25022 5.9924 1.19466 5.33612C1.13911 4.67983 1.11133 3.96454 1.11133 3.19022V0.555664H2.90299V3.19022Z"
          fill="white"
        />
        <path
          opacity="0.904692"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.11089 9.43661C3.11089 9.76711 3.00441 10.0362 2.79145 10.244C2.57849 10.4517 2.3146 10.5556 1.99978 10.5556C1.69423 10.5556 1.43265 10.4517 1.21506 10.244C0.997467 10.0362 0.888672 9.76711 0.888672 9.43661C0.888672 9.10611 0.997467 8.83463 1.21506 8.62216C1.43265 8.4097 1.69423 8.30347 1.99978 8.30347C2.3146 8.30347 2.57849 8.4097 2.79145 8.62216C3.00441 8.83463 3.11089 9.10611 3.11089 9.43661Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderAvatar",
  props: ["isPay"],
  computed: {
    getAccount() {
      return this.$store.getters.getAccount;
    },
    getPay() {
      return this.$store.getters.getIsPay;
    },
  },
};
</script>

<style scoped lang="scss">
.avatar {
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  z-index: 3;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.2) 100%
    );
    border-radius: 10em;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.2),
      inset 0px 1px 0px rgba(255, 255, 255, 0.157326),
      inset 0px -1px 0px rgba(0, 0, 0, 0.05);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 10em;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.0001) 100%
    );
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.2),
      inset 0px 1px 0px rgba(255, 255, 255, 0.157326),
      inset 0px -1px 0px rgba(0, 0, 0, 0.05);
  }

  &__image {
    width: 38px;
    height: 38px;
    border-radius: 10em;
  }
  &__dot {
    position: absolute;
    top: -4px;
    right: -4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 10em;
    background: #fd5739;
  }
}
</style>