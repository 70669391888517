<template>
  <div
    class="header"
    :class="[
      getType === 'default' ? 'header_white' : 'header_black',
      getType === 'list' ? 'header_list' : 'header_default',
      getAccount && getAccount.type === 2 ? 'header_sub' : '',
      getAccount && getAccount.type === 2 && $windowWidth > 992 ? 'header_sidebar' : '',
      `header_type-${getType}`,
      getType === 'list' && !$store.getters.getIsApp
        ? 'header_noapp_black'
        : '',
    ]"
    v-if="$store.getters.getTypeView === 'default' || $store.getters.getTypeView === 'list' || ($store.getters.getTypeView === 'column' && ($windowWidth > 992 || $windowWidth < $windowHeight))"
  >
    <div class="header__container">
      <div class="header__top" :class="getType === 'list' ? 'header__top_list' : 'header__top_default'">
        <div class="header__theme">
          <div
            class="header__back"
            v-if="getAccount.type === 2 && $windowWidth < 993 && $route.query.account"
            @click="goToHome"
          >
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 20 20"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Artboard"
                  transform="translate(-20.000000, -60.000000)"
                  fill="#4B4B4B"
                  fill-rule="nonzero"
                >
                  <polygon
                    id="Back"
                    points="40 68.75 24.7875 68.75 31.775 61.7625 30 60 20 70 30 80 31.7625 78.2375 24.7875 71.25 40 71.25"
                  ></polygon>
                </g>
              </g>
            </svg>
          </div>
          <type-settings></type-settings>
        </div>
        <div class="header__avatar">
          <div class="header__speed">
            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="getSpeedByVideos >= 1">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M21 0C20.4477 0 20 0.447716 20 1V23C20 23.5523 20.4477 24 21 24H25C25.5523 24 26 23.5523 26 23V1C26 0.447715 25.5523 0 25 0H21ZM10 9C10 8.44771 10.4477 8 11 8H15C15.5523 8 16 8.44771 16 9V23C16 23.5523 15.5523 24 15 24H11C10.4477 24 10 23.5523 10 23V9ZM0 17C0 16.4477 0.447715 16 1 16H5C5.55228 16 6 16.4477 6 17V23C6 23.5523 5.55228 24 5 24H1C0.447715 24 0 23.5523 0 23V17Z" fill="#85FF00"/>
            </svg>
            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="getSpeedByVideos < 1 && getSpeedByVideos >= 0.5">
              <rect y="16" width="6" height="8" rx="1" fill="#FFB43A"/>
              <rect x="10" y="8" width="6" height="16" rx="1" fill="#FFB43A"/>
              <rect opacity="0.500431" x="20" width="6" height="24" rx="1" fill="#C5C4C6"/>
            </svg>
            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
              <rect y="16" width="6" height="8" rx="1" fill="#DD0000"/>
              <rect opacity="0.500659" x="10" y="8" width="6" height="16" rx="1" fill="#C5C4C6"/>
              <rect opacity="0.500659" x="20" width="6" height="24" rx="1" fill="#C5C4C6"/>
            </svg>
          </div>
          <header-avatar
            :is-pay="true"
            @changeMenu="changeMenu"
          ></header-avatar>
          <div class="header__menu" :class="showMenu ? 'open' : ''">
            <ul v-if="getAccount && getAccount.type !== 2 || getAccount && getAccount.type === 2 && !$route.query.account">
<!--              <li>-->
<!--                <router-link-->
<!--                        class="header__tarif"-->
<!--                  :to="{ name: 'Payments' }"-->
<!--                  :class="!$store.getters.getIsPay ? 'header__pay' : ''"-->
<!--                >-->
<!--                  <svg-->
<!--                    width="14"-->
<!--                    height="9"-->
<!--                    viewBox="0 0 14 9"-->
<!--                    fill="none"-->
<!--                    xmlns="http://www.w3.org/2000/svg"-->
<!--                  >-->
<!--                    <path-->
<!--                      fill-rule="evenodd"-->
<!--                      clip-rule="evenodd"-->
<!--                      d="M14 1.43182V2.12216C14 2.19275 13.9388 2.25 13.8633 2.25H0.136719C0.0612227 2.25 0 2.19275 0 2.12216V1.43182C0 0.641045 0.685562 0 1.53125 0H12.4688C13.3144 0 14 0.641045 14 1.43182ZM0 3.19602V7.56818C0 8.35895 0.685562 9 1.53125 9H12.4688C13.3144 9 14 8.35895 14 7.56818V3.19602C14 3.12543 13.9388 3.06818 13.8633 3.06818H0.136719C0.0612227 3.06818 0 3.12543 0 3.19602ZM3.5 6.54545C3.5 6.77137 3.30411 6.95455 3.0625 6.95455H2.625C2.38339 6.95455 2.1875 6.77137 2.1875 6.54545V6.13636C2.1875 5.91044 2.38339 5.72727 2.625 5.72727H3.0625C3.30411 5.72727 3.5 5.91044 3.5 6.13636V6.54545Z"-->
<!--                      fill="#B4B4B4"-->
<!--                    />-->
<!--                  </svg>-->
<!--                  <span>Управление тарифом</span>-->
<!--                </router-link>-->
<!--              </li>-->
              <li>
                <span @click="exit">Выход</span>
              </li>
            </ul>
            <ul v-else>
              <li>{{ $store.getters.getSubAccount.name }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="header__bottom" v-if="$store.getters.getTypeView === 'list'">
        <search-input theme="white"></search-input>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import TypeSettings from "./TypeSettings";
import HeaderAvatar from "./HeaderAvatar";
import SearchInput from "../forms/SearchInput";
export default {
  name: "MainHeader",
  components: {SearchInput, HeaderAvatar, TypeSettings },
  data() {
    return {
      showMenu: false,
      startTime: undefined,
      endTime: undefined
    };
  },
  computed: {
    getType() {
      return this.$store.getters.getTypeView;
    },
    getAccount() {
      return this.$store.getters.getAccount;
    },
    getSpeedByVideos() {
      const speed = this.$store.getters.getSpeed
      const count = this.$store.getters.getVideoCount
      if (count === 0) {
        return 3
      }
      else {
        return speed / count
      }
    }
  },
  methods: {
    goToHome() {
      localStorage.removeItem('subAccount')
      this.$store.commit('changeViewType', 'default')
      this.$store.commit('changeCameras', [])
      this.$router.push({path: '/' });
    },
    changeMenu() {
      this.showMenu = !this.showMenu;
    },
    exit() {
      this.$store.dispatch("exitFromAccount");
      this.$router.push({ name: "Auth" });
    },
    loadSpeedTest() {
      const link = require('../../assets/icons/archive.svg')
      this.startTime = (new Date()).getTime()
      axios.get(link).then(resp => {
        let kb = resp.headers['content-length']
        if (resp.status === 200) {
          this.endTime = (new Date()).getTime()
          const time = this.endTime - this.startTime
          this.$store.commit('changeSpeed', ((kb / 1024) / (time / 1000)))
        }
      })
    }
  },
  mounted() {
    setTimeout(() => {
      this.loadSpeedTest()
    }, 500)
    this.interval = setInterval(() => {
      this.loadSpeedTest()
    }, 1000 * 20)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
};
</script>

<style scoped lang="scss">
.header {
  //position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 66px;
  z-index: $z-header;
  padding: 0 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_sidebar {
    padding-left: 230px;
  }
  &_list {
    height: 122px;
    //position: fixed;
  }
  &_noapp_black {
    background-color: #0f0f0f;
  }
  &_sub {
    left: 220px;
    @include media(992) {
      left: 0;
    }
  }

  &__theme {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__container {
    width: 100%;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__bottom {
    padding-top: 10px;
  }

  &__back {
    margin-right: 16px;
    cursor: pointer;
  }
  &__speed {
    padding-right: 12px;
    svg {
      vertical-align: middle;
      width: 24px;
      height: auto;
    }
  }
  &__avatar {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__menu {
    margin-top: 10px;
    position: absolute;
    top: 100%;
    right: 0;
    width: 191px;
    z-index: 20;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding-top: 18px;
    padding-left: 35px;
    padding-bottom: 35px;
    padding-right: 10px;
    display: none;
    ul {
      a {
        display: block;
      }
      li {
        position: relative;
        margin-bottom: 11px;
        &:last-child {
          margin-bottom: 0;
        }
        svg {
          position: absolute;
          left: -25px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    &.open {
      display: block;
    }
    span {
      font-size: em(14);
    }
    a,
    span {
      cursor: pointer;
      font-family: Roboto, "sans-serif";
      color: #0f0f0f;
      text-decoration: none;
      &.router-link-active {
        color: #fd5739;
      }
    }
  }

  &__pay {
    svg {
      * {
        fill: #fd5739;
      }
    }
  }
  &__top {
    &_list {
      position: relative;
      top: -5px;
    }
  }
  &__tarif {
    margin-top: 10px;
  }
}
</style>