<template>
  <div id="app" class="app" :class="[getClassTheme, getAuthClass, $store.getters.getTypeView === 'list' ? 'app_list' : '']">
    <loader-pay class="app__loader" v-if="isLoading"></loader-pay>
    <main-header
      v-if="(getAuth.id && getHeader && getAuth.type !== 2) || (getAuth.id && getHeader && getAuth.type === 2 &&  $route.query.account) "
    ></main-header>
    <router-view />
    <video-share-modal></video-share-modal>
    <div class="footer--text" v-if="!getAuth.id">
      <p>EYE</p>
    </div>
  </div>
</template>
<script>
import MainHeader from "./components/layouts/MainHeader";
import VideoShareModal from "./components/video/VideoShareModal";
import LoaderPay from "./components/loaders/LoaderPay";
export default {
  name: "App",
  data() {
    return {
      isLoading: true
    }
  },
  components: {LoaderPay, VideoShareModal, MainHeader },
  computed: {
    getAuth() {
      return this.$store.getters.getAccount;
    },
    getClassTheme() {
      if (!this.$store.getters.getIsApp) {
        const theme = this.$store.getters.getTypeView;
        if (theme !== "default") {
          return "app_black";
        } else {
          return "app_white";
        }
      }
      return null;
    },
    getAuthClass() {
      if (!this.$store.getters.getAccount.id) {
        return "app_auth";
      }
      return null;
    },
    getHeader() {
      return this.$store.getters.getShowHeader;
    },
  },
  mounted() {
    this.$store.commit("changeViewType", "default");
    if (window.location.href.indexOf("auth") == -1) {
      this.$store.dispatch("loadAccount", {
        token: localStorage.getItem("accessToken"),
      });
    }
    this.isLoading = false
    this.$store.commit("changeShowHeader", true);
  },
};
</script>
<style lang="scss" scoped>
.app {
  overflow: hidden;
  position: relative;
  height: 100vh;
  &_list {
    height: auto;
  }
  &_white {
    background-color: #f8f8f8;
  }
  &_black {
    background-color: #0f0f0f;
  }
  &_white-super {
    background-color: #fff;
  }
  &_auth {
    height: 100vh;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loader {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.footer--text {
  position: absolute;
  bottom: 30px;
  font-size: em(24);
  color: #aeaeae;
  text-align: center;
  font-weight: 600;
  opacity: 0.5;
}
</style>
