import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      name: 'Home',
      meta: {
          title: 'Главная'
      },
      component: () => import('../views/Home')
    },
    {
        path: '/auth',
        name: 'Auth',
        meta: {
            title: 'Авторизация'
        },
        component: () => import('../views/Auth')
    },
    // {
    //     path: '/payments',
    //     name: 'Payments',
    //     meta: {
    //         title: 'Оплата'
    //     },
    //     component: () => import('../views/Payments')
    // },
    {
        path: '/archive',
        name: 'Archive',
        meta: {
            title: 'Архив'
        },
        component: () => import('../views/Archive')
    },
    {
        path: '/share/:token',
        name: 'EyeShareView',
        meta: {
            title: 'EyeShare'
        },
        component: () => import('../views/Share')
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
