import axios from "../libs/axios";

export default {
    state: {
        isApp: false,
        search: '',
        typeView: '',
        showHeader: true,
        video: {
            volume: 0,
            volumeSize: 0
        },
        isFullscreen: false,
        speed: 0,
        videosCount: 0,
        shareData: null
    },
    getters: {
        getIsApp(state) {
            return state.isApp
        },
        getSpeed(state) {
            return state.speed
        },
        getVideoCount(state) {
            return state.videosCount
        },
        getVideo(state) {
            return state.video
        },
        getVideoSize(state) {
            return state.video.volumeSize
        },
        getTypeView(state) {
            return state.typeView
        },
        getShowHeader(state) {
            return state.showHeader
        },
        getSearch(state) {
            return state.search
        },
        getIsFullScreen(state){
            return state.isFullscreen;
        },
        getShareData(state){
            return state.shareData
        }
    },
    mutations: {
        changeIsApp(state, payload) {
            return state.isApp = payload
        },
        changeSpeed(state, payload) {
            return state.speed = payload
        },
        changeVideoCount(state, payload) {
          if (payload) {
              return state.videosCount++
          }
          else {
              if (state.videosCount >= 1) {
                  return state.videosCount--
              }
              else {
                  return  state.videosCount = 0
              }
          }
        },
        changeViewType(state, payload) {
            localStorage.setItem('typeView', payload)
            const isApp = parseInt(localStorage.getItem('isApp'))
            // eslint-disable-next-line eqeqeq
            if (isApp === 1) {
                const typeToApp = payload === 'default' ? 0 : 1
                try {
                    // eslint-disable-next-line no-undef
                    androidAbility.changeBackgroundColor(typeToApp)
                } catch (e) {
                    window.webkit.messageHandlers.changeBackgroundColorHandler.postMessage(typeToApp)
                }
            } else {
                // забей здесь
            }
            return state.typeView = payload
        },

        changeFullScreen(state,payload) {
            const isApp = parseInt(localStorage.getItem('isApp'))
            if (isApp === 1) {
                try {
                    // eslint-disable-next-line no-undef
                    androidAbility.changeFullScreen(payload)
                } catch (e) {
                    window.webkit.messageHandlers.changeFullScreenHandler.postMessage(payload)
                }
            } else {
                // забей здесь
            }

            return state.isFullscreen = !!payload

            },
        changeShowHeader(state, payload) {
            return state.showHeader = payload
        },
        changeSearch(state, payload) {
            return state.search = payload
        },
        changeVideo(state, payload) {
            return state.video = payload
        },
        changeVideoVolume(state, payload) {
            return state.video.volumeSize = payload
        },
        changeShareData(state,payload) {
            return state.shareData = payload
        }
    },
    actions: {
        async createShare(store, {cameraId}) {
            const res = await axios.post(`share/camera/${cameraId}`)
            return res.data
        },
        async getShare({commit},{token}) {
            const sh = await axios.get(`share/${token}`)
            return commit('changeShareData', sh.data)
        }
    },
}
